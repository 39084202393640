// src/services/authService.ts
import { BASE_URL } from "../utils/BaseInfo";

// 判断 token 是否过期
export const isTokenExpired = (token: string): boolean => {
	try {
		const base64Url = token.split(".")[1];
		if (!base64Url) return true;

		const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
		const jsonPayload = decodeURIComponent(
			atob(base64)
				.split("")
				.map((c) => `%${("00" + c.charCodeAt(0).toString(16)).slice(-2)}`)
				.join("")
		);
		const { exp } = JSON.parse(jsonPayload);
		return exp && Date.now() >= exp * 1000;
	} catch (error) {
		console.error("Failed to parse token", error);
		return true; // 如果解析失败，返回 true 表示 token 已过期
	}
};


// 验证 token
export const validateToken = async (token: string): Promise<boolean> => {
	try {
		const response = await fetch(`${BASE_URL}/api/account/validateToken`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ token }),
		});
		const data = await response.json();
		return data.isValid;
	} catch (error) {
		console.error("Token validation failed", error);
		return false;
	}
};
