// 错误信息缓存的 Key
const ERROR_CACHE_KEY = "errorCache";
const ERROR_VERSION_KEY = "errorVersion";

// 从后端获取最新错误定义并缓存
export async function fetchAndCacheErrors() {
    try {
        // 获取后端的最新错误版本号
        const remoteVersion = await getRemoteVersion();

        // 检查本地版本号是否匹配
        const localVersion = getCachedErrorVersion();
        if (localVersion === remoteVersion) {
            console.log("错误信息版本未改变，使用缓存数据。");
            return getCachedErrorData();
        }

        // 如果版本不匹配或没有缓存，获取最新错误定义
        console.log("错误信息版本已更新，重新获取错误定义。");
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/errors/definitions`);
        const errors = await response.json();

        // 缓存错误信息和版本号
        cacheErrorData(errors, remoteVersion);
        return errors;
    } catch (error) {
        console.error("获取错误信息失败:", error);
        throw error;
    }
}

// 缓存错误信息和版本号
function cacheErrorData(errors: any, version: string) {
    localStorage.setItem(ERROR_CACHE_KEY, JSON.stringify(errors));
    localStorage.setItem(ERROR_VERSION_KEY, version);
}

// 获取缓存的错误信息
export function getCachedErrorData() {
    const cached = localStorage.getItem(ERROR_CACHE_KEY);
    if (cached) {
        return JSON.parse(cached);
    }
    return null;
}

// 获取缓存的版本号
export function getCachedErrorVersion() {
    return localStorage.getItem(ERROR_VERSION_KEY);
}

// 从后端获取最新版本号
export async function getRemoteVersion() {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/errors/version`);
        const data = await response.json();
        return data.version;
    } catch (error) {
        console.error("获取版本号失败:", error);
        return null;
    }
}