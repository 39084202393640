// src/components/ChatComponent.tsx
import React from "react";
import { ProChat, ChatMessage, ProChatInstance } from "@ant-design/pro-chat";
import { useTheme } from "antd-style";
import { Card, Result, Button } from "antd";

interface ChatComponentProps {
  loading: boolean;
  error: { message: string; type: string } | null;
  initialChats: ChatMessage<Record<string, any>>[];
  handleRequest: (messages: ChatMessage<Record<string, any>>[]) => Promise<Response>;
  proChatRef: React.MutableRefObject<ProChatInstance | undefined>;  // 将类型改为 ProChatInstance | undefined
}

const ChatComponent: React.FC<ChatComponentProps> = ({
  loading,
  error,
  initialChats,
  handleRequest,
  proChatRef,
}) => {
  const theme = useTheme();

  if (loading) {
    return <div>加载中...</div>;
  }

  if (error) {
    return (
      <Card>
        <Result
          status="error"
          title="出现错误"
          subTitle={error.message}
          extra={[
            <Button type="primary" key="console" onClick={() => window.location.reload()}>
              重试
            </Button>,
          ]}
        />
      </Card>
    );
  }

  return (
    <div style={{ background: theme.colorBgLayout, height: "80vh", width: "80vw" }}>
      <ProChat
        chatRef={proChatRef}  // 这里传递的 proChatRef 是 MutableRefObject
        helloMessage="欢迎使用"
        style={{ height: "80vh", width: "80vw" }}
        request={handleRequest}
        initialChats={initialChats}
      />
    </div>
  );
};

export default ChatComponent;
