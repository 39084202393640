import React, { useState } from "react";
import { Form, Input, Button, Typography, Alert } from "antd";
import { FetchWithErrorHandling } from "../../utils/FetchWithErrorHandling";

const { Text } = Typography;

const Register = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [verificationSent, setVerificationSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [message, setMessage] = useState("");
    // const [isExistingUser, setIsExistingUser] = useState(false);

    // 检查用户是否存在
    const handleCheckUserExists = async (email: any) => {
        try {
            const data = await FetchWithErrorHandling(
                `${process.env.REACT_APP_BASE_URL}/api/account/checkuserexists`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ email }),
                }
            );
            return data.isExistingUser;
        } catch (err) {
            setMessage(typeof err === 'string' ? err : '发生错误，请重试。'); // 自动处理的错误消息
            return false;
        }
    };

    // 发送验证码
    const handleSendCode = async () => {
        try {
            setLoading(true);
            const email = form.getFieldValue("email");
            const userExists = await handleCheckUserExists(email);

            if (userExists) {
                setErrorMessage("该邮箱已注册，请直接登录！");
                // setIsExistingUser(true);
                setLoading(false);
                return;
            }

            await FetchWithErrorHandling(
                `${process.env.REACT_APP_BASE_URL}/api/account/sendverificationCode`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ email }),
                }
            );

            setVerificationSent(true);
            setMessage("验证码已发送，请检查您的邮箱。");
            setErrorMessage("");
        } catch (err) {
            setMessage(typeof err === 'string' ? err : '发生错误，请重试。'); // 自动处理的错误消息
        } finally {
            setLoading(false);
        }
    };

    // 提交注册请求
    const handleRegister = async (values: any) => {
        try {
            setLoading(true);

            await FetchWithErrorHandling(
                `${process.env.REACT_APP_BASE_URL}/api/account/register`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(values),
                }
            );

            setMessage("注册成功，请检查您的邮箱以确认账号！");
            setErrorMessage("");
        } catch (err) {
            setMessage(typeof err === 'string' ? err : '发生错误，请重试。'); // 自动处理的错误消息
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ padding: "20px 0" }}>
            <Form
                form={form}
                onFinish={handleRegister}
                layout="vertical"
                style={{
                    maxWidth: 400,
                    margin: "0 auto",
                    padding: 20,
                    // backgroundColor: "#fff",
                    // borderRadius: 8,
                    // boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                }}
                requiredMark={false}
            >{/* 邮箱输入框 */}
                <Form.Item
                    name="email"
                    label={<Text style={{ fontSize: 16, fontWeight: 500 }}>邮箱</Text>}
                    rules={[
                        { required: true, message: "请输入邮箱地址！" },
                        { type: "email", message: "请输入有效的邮箱地址！" },
                    ]}
                >
                    <Input
                        placeholder="example@domain.com"
                        size="large"
                        style={{
                            borderRadius: 8,
                            padding: "10px",
                            fontSize: 14,
                            border: "1px solid #d9d9d9",
                            boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                        }}
                        onChange={(e) => {
                            setErrorMessage("");
                            setMessage("");
                            setVerificationSent(false);
                            // setIsExistingUser(false);
                            form.resetFields(["password", "code"]); // 重置密码和验证码字段
                        }}
                    />
                </Form.Item>

                {/* 验证码输入框 */}
                {verificationSent && (
                    <Form.Item
                        name="code"
                        label={<Text style={{ fontSize: 16, fontWeight: 500 }}>验证码</Text>}
                        rules={[{ required: true, message: "请输入验证码！" }]}
                    >
                        <Input
                            placeholder="请输入验证码"
                            size="large"
                            style={{
                                borderRadius: 8,
                                padding: "10px",
                                fontSize: 14,
                                border: "1px solid #d9d9d9",
                                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                            }}
                        />
                    </Form.Item>
                )}

                {/* 密码输入框 */}
                {verificationSent && (
                    <Form.Item
                        name="password"
                        label={<Text style={{ fontSize: 16, fontWeight: 500 }}>密码</Text>}
                        rules={[{ required: true, message: "请输入密码！" }]}
                    >
                        <Input.Password
                            placeholder="请输入密码"
                            size="large"
                            style={{
                                borderRadius: 8,
                                padding: "10px",
                                fontSize: 14,
                                border: "1px solid #d9d9d9",
                                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                            }}
                        />
                    </Form.Item>
                )}

                {/* 错误提示 */}
                <div
                    style={{
                        height: 50,
                        marginBottom: 20,
                        transition: "all 0.3s ease-in-out",
                        transform: errorMessage ? "translateY(0)" : "translateY(-20px)",
                        opacity: errorMessage ? 1 : 0,
                        visibility: errorMessage ? "visible" : "hidden",
                    }}
                >
                    {errorMessage && (
                        <Alert message={errorMessage} type="error" showIcon />
                    )}
                </div>

                {/* 消息提示 */}
                {message && (
                    <div
                        style={{
                            height: 50,
                            marginBottom: 20,
                            color: "#52c41a",
                            fontWeight: 500,
                            textAlign: "center",
                        }}
                    >
                        {message}
                    </div>
                )}

                {/* 发送验证码或注册按钮 */}
                {!verificationSent ? (
                    <Button
                        type="primary"
                        onClick={handleSendCode}
                        loading={loading}
                        block
                        size="large"
                        style={{
                            borderRadius: 8,
                            fontSize: 16,
                            fontWeight: 500,
                            padding: "10px 0",
                            backgroundColor: "#1890ff",
                            borderColor: "#1890ff",
                            transition: "all 0.3s",
                        }}
                    >
                        发送验证码
                    </Button>
                ) : (
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        block
                        size="large"
                        style={{
                            borderRadius: 8,
                            fontSize: 16,
                            fontWeight: 500,
                            padding: "10px 0",
                            backgroundColor: "#1890ff",
                            borderColor: "#1890ff",
                            transition: "all 0.3s",
                        }}
                    >
                        注册
                    </Button>
                )}
            </Form>
        </div>
    );
};

export default Register;