// src/utils/language.js

// 默认语言
let currentLanguage = "zh";

/**
 * 获取当前语言
 * @returns {string} 当前语言（"zh" 或 "en"）
 */
export function getLanguage() {
    return currentLanguage;
}

/**
 * 设置语言
 * @param {string} language - 要设置的语言（"zh" 或 "en"）
 */
export function setLanguage(language: string) {
    if (["zh", "en"].includes(language)) {
        currentLanguage = language;
    } else {
        console.warn(`Unsupported language: ${language}. Falling back to "zh".`);
        currentLanguage = "zh";
    }
}