// src/components/Login/EmailLoginComponent.tsx
import React, { useState } from "react";
import { Button, Input, Form, Typography, Alert } from "antd";

import { useNavigate } from "react-router-dom";
import { FetchWithErrorHandling } from "../../utils/FetchWithErrorHandling";
import { useAuth } from "../../contexts/AuthContext";

const { Text } = Typography;

const EmailLoginComponent: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { login } = useAuth(); // 使用 AuthContext 的 login 方法
  const navigate = useNavigate(); // 用于重定向

  const handleLogin = async (values: { email: string; password: string }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      // 使用封装的 FetchWithErrorHandling
      const data = await FetchWithErrorHandling(
        `${process.env.REACT_APP_BASE_URL}/api/account/login`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(values),
        }
      );

      // 使用 AuthContext 的 login 方法
      login(data.token);

      // 重定向到仪表盘或其他页面
      navigate("/dashboard");
    } catch (err: any) {
      setErrorMessage(err || "登录失败，请稍后再试！");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: "20px 0" }}>
      <Form
        form={form}
        onFinish={handleLogin}
        layout="vertical"
        style={{
          maxWidth: 400,
          margin: "0 auto",
          padding: 20,
        }}
        requiredMark={false}
      >
        {/* 邮箱输入框 */}
        <Form.Item
          name="email"
          label={<Text style={{ fontSize: 16, fontWeight: 500 }}>邮箱</Text>}
          rules={[
            { required: true, message: "请输入邮箱地址！" },
            { type: "email", message: "请输入有效的邮箱地址！" },
          ]}
        >
          <Input
            placeholder="example@domain.com"
            size="large"
            style={{
              borderRadius: 8,
              padding: "10px",
              fontSize: 14,
              border: "1px solid #d9d9d9",
              boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
            }}
          />
        </Form.Item>

        {/* 密码输入框 */}
        <Form.Item
          name="password"
          label={<Text style={{ fontSize: 16, fontWeight: 500 }}>密码</Text>}
          rules={[{ required: true, message: "请输入密码！" }]}
        >
          <Input.Password
            placeholder="请输入密码"
            size="large"
            style={{
              borderRadius: 8,
              padding: "10px",
              fontSize: 14,
              border: "1px solid #d9d9d9",
              boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
            }}
          />
        </Form.Item>

        {/* 错误提示 */}
        <div
          style={{
            height: 50,
            marginBottom: 20,
            transition: "all 0.3s ease-in-out",
            transform: errorMessage ? "translateY(0)" : "translateY(-20px)",
            opacity: errorMessage ? 1 : 0,
            visibility: errorMessage ? "visible" : "hidden",
          }}
        >
          {errorMessage && (
            <Alert message={errorMessage} type="error" showIcon />
          )}
        </div>

        {/* 登录按钮 */}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            block
            size="large"
            style={{
              borderRadius: 8,
              fontSize: 16,
              fontWeight: 500,
              padding: "10px 0",
              backgroundColor: "#1890ff",
              borderColor: "#1890ff",
              transition: "all 0.3s",
            }}
          >
            登录
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EmailLoginComponent;
