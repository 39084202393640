import React from "react";
import { Row, Col, Card } from 'antd';

const { Meta } = Card;

const ProductPage = () => {
  const products = [
    {
      title: 'Product 1',
      description: 'ChatGPT4.0 5$ per month',
      image: 'https://via.placeholder.com/150'
    },
    {
      title: 'Product 2',
      description: 'This is product 2',
      image: 'https://via.placeholder.com/150'
    },
    // Add more products as needed
  ];

  return (
    <>
      <Row gutter={16}>
        {products.map((product, index) => (
          <Col span={6} key={index}>
            <Card
              hoverable
              cover={<img alt={product.title} src={product.image} />}
            >
              <Meta title={product.title} description={product.description} />
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default ProductPage;
