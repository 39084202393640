import React, { useState } from "react";
import { Layout, Card, Space, Typography, Button } from "antd";
import EmailLoginComponent from "../components/login/EmailLoginComponent";
import ForgetPasswordComponent from "../components/login/ForgetPasswordComponent";
import RegisterOrLoginComponent from "../components/login/RegisterComponent";
import WeChatLoginComponent from "../components/login/WeChatLoginComponent";

const { Content } = Layout;
const { Link, Title } = Typography;

const LoginPage: React.FC = () => {
  // 状态：当前页面模式
  const [authMode, setAuthMode] = useState<
    "login" | "register" | "forgotPassword" | "wechat"
  >("login");

  // 根据状态返回标题
  const getTitle = () => {
    switch (authMode) {
      case "login":
        return "登录到您的账户";
      case "register":
        return "注册或登录";
      case "forgotPassword":
        return "忘记密码";
      case "wechat":
        return "微信扫码登录";
      default:
        return "登录";
    }
  };

  // 根据状态渲染内容组件
  const renderContent = () => {
    switch (authMode) {
      case "login":
        return <EmailLoginComponent />;
      case "register":
        return <RegisterOrLoginComponent />;
      case "forgotPassword":
        return <ForgetPasswordComponent />;
      case "wechat":
        return <WeChatLoginComponent />;
      default:
        return <EmailLoginComponent />;
    }
  };

  return (
    <Layout style={{ minHeight: "100vh", backgroundColor: "#f0f2f5" }}>
      <Content
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          style={{
            width: 400,
            borderRadius: 8,
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            // border: 12,
            padding: 12,
          }}
        >
          {/* 动态标题 */}
          <Title level={3} style={{ textAlign: "center", marginBottom: 24 }}>
            {getTitle()}
          </Title>

          {/* 渲染内容 */}
          {renderContent()}

          {/* 底部切换链接 */}
          {authMode === "login" && (
            <Space
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 16,
              }}
            >
              <Link onClick={() => setAuthMode("forgotPassword")}>
                忘记密码？
              </Link>
              <Link onClick={() => setAuthMode("register")}>注册</Link>
            </Space>
          )}

          {authMode !== "login" && authMode !== "wechat" && (
            <Space
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 16,
              }}
            >
              <Link onClick={() => setAuthMode("login")}>返回登录</Link>
            </Space>
          )}

          {/* 微信登录按钮 */}
          {authMode === "login" && (
            <div style={{ textAlign: "center", marginTop: 16 }}>
              <Button
                type="primary"
                style={{ width: "100%" }}
                onClick={() => setAuthMode("wechat")}
              >
                使用微信登录
              </Button>
            </div>
          )}
        </Card>
      </Content>
    </Layout>
  );
};

export default LoginPage;
