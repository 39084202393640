import { getCachedErrorData } from "../api/ErrorService";
import { getLanguage } from "./Languages";

// 定义错误类型枚举
export enum ErrorType {
    Http = 0,
    ErrorCode = 1,
}

// 错误信息配置
const errorMessages: Record<ErrorType, Record<string | number, Record<string, string>>> = {
    [ErrorType.Http]: {
        "400": {
            zh: "请求无效，请检查您的输入。",
            en: "Bad Request. Please check your input.",
        },
        "401": {
            zh: "未授权，请检查您的凭据。",
            en: "Unauthorized. Please check your credentials.",
        },
        "403": {
            zh: "您没有权限执行此操作。",
            en: "Forbidden. You do not have permission to perform this action.",
        },
        "404": {
            zh: "请求的资源未找到。",
            en: "Resource not found.",
        },
        "405": {
            zh: "请求的方法不允许。",
            en: "Method not allowed.",
        },
        "500": {
            zh: "服务器内部错误，请稍后再试。",
            en: "Internal server error. Please try again later.",
        },
        "503": {
            zh: "服务不可用，请稍后再试。",
            en: "Service unavailable. Please try again later.",
        },
        default: {
            zh: "发生未知的服务器错误。",
            en: "An unknown server error occurred.",
        },
    },
    [ErrorType.ErrorCode]: {
        NetworkError: {
            zh: "无法连接到服务器，请检查网络连接。",
            en: "Unable to connect to the server. Please check your network connection.",
        },
        UnknownError: {
            zh: "发生未知错误。",
            en: "An unknown error occurred.",
        },
    },
};

function GetErrorMessage(type: ErrorType, key: string | number): string {
    const language = getLanguage();
    const messages = errorMessages[type];
    return (
        messages[key]?.[language] || messages.default?.[language] || "An error occurred."
    );
}

function HandleHttpError(statusCode: number): string {
    return GetErrorMessage(ErrorType.Http, statusCode);
}

function HandleErrorCode(errorCode: string): string {
    const cachedData = getCachedErrorData();

    if (errorCode === "NetworkError") {
        return GetErrorMessage(ErrorType.ErrorCode, errorCode);
    }

    if (cachedData) {
        const error = cachedData.find((err: any) => err.code === errorCode);

        if (error) {
            const language = getLanguage();
            return error.message[language] || error.message["zh"];
        }
    }

    console.error("未找到对应的错误信息:", errorCode);
    return GetErrorMessage(ErrorType.ErrorCode, "UnknownError");
}

export async function HandleApiError(
    error: any, // 捕获 fetch 抛出的错误对象
): Promise<string> {
    // 如果是 Response 对象（服务端错误）
    if (error instanceof Response) {
        try {
            const errorData = await error.json();
            if (errorData?.errorCode) {
                return HandleErrorCode(errorData.errorCode);
            }
        } catch {
            // 忽略 JSON 解析错误，继续处理 HTTP 状态码
        }
        return HandleHttpError(error.status);
    }
    return GetErrorMessage(ErrorType.ErrorCode, "NetworkError");
}