import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import HomePage from "./pages/HomePage";
import ChatPage from "./pages/ChatPage";
import LoginCombined from "./pages/LoginPage";
import "./App.css";
import "stream-browserify";
import { AuthProvider, ProtectedRoute } from "./contexts/AuthContext";
import ProductPage from "./pages/ProductPage";
import CartPage from "./pages/CartPage";
// import SiteHeader from "./container/SiteHeaderComponent";
import SiteHeader from "./containers/SiteHeaderContainer";
import UserInfoPage from "./pages/UserInfoPage";

import { initializeErrorCache } from "./utils/Initializer"; // 导入初始化函数

function AppContent() {
  const location = useLocation();
  const isChatPage = location.pathname.startsWith("/chat");
  return (
    <div className="App">
      {/* {!isChatPage && <SiteHeader />} */}
      <Routes>
        {/* <Route path="/" element={<HomePage />} /> */}
        <Route
          path="/chat"
          element={
            <ProtectedRoute element={<ChatPage />} redirectTo="/login" />
          }
        />
        <Route
          path="/chat/:sessionId"
          element={
            <ProtectedRoute element={<ChatPage />} redirectTo="/login" />
          }
        />
        <Route
          path="/cart"
          element={
            <ProtectedRoute element={<CartPage />} redirectTo="/login" />
          }
        />
        <Route
          path="/products"
          element={
            <ProtectedRoute element={<ProductPage />} redirectTo="/login" />
          }
        />
        <Route path="/login" element={<LoginCombined />} />
        <Route
          path="/user/:userId"
          element={
            <ProtectedRoute element={<UserInfoPage />} redirectTo="/login" />
          }
        />
      </Routes>
    </div>
  );
}

function App() {
  // 在 App 组件中调用初始化逻辑
  useEffect(() => {
    const initializeApp = async () => {
      try {
        // 调用初始化错误缓存的函数
        await initializeErrorCache();
        console.log("错误信息初始化完成！");
      } catch (error) {
        console.error("初始化错误信息失败:", error);
      }
    };

    initializeApp(); // 执行初始化
  }, []);

  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;