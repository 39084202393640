import React, { useEffect, useState } from "react";

const WeChatLoginComponent: React.FC = () => {
    const [qrConnectUrl, setQrConnectUrl] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        // 获取后端生成的二维码登录 URL
        const fetchQrConnectUrl = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/wechat/oauth/login`);
                if (!response.ok) {
                    throw new Error("无法获取微信登录二维码，请稍后重试。");
                }
                const data = await response.json();
                if (data?.qrConnectUrl) {
                    setQrConnectUrl(data.qrConnectUrl);
                } else {
                    throw new Error("二维码 URL 数据无效，请稍后重试。");
                }
            } catch (err) {
                console.error("Error fetching WeChat login URL:", err);
                setError(err instanceof Error ? err.message : "未知错误");
            }
        };

        fetchQrConnectUrl();
    }, []);

    return (
        <div>
            <h3>微信扫码登录</h3>
            <div style={{ textAlign: "center" }}>
                {qrConnectUrl ? (
                    <a href={qrConnectUrl} target="_blank" rel="noopener noreferrer">
                        <img
                            src={`https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(qrConnectUrl)}&size=200x200`}
                            alt="WeChat QR Code"
                            style={{ width: "200px", height: "200px" }}
                        />
                    </a>
                ) : error ? (
                    <p style={{ color: "red" }}>{error}</p>
                ) : (
                    <p>正在加载二维码...</p>
                )}
                <p>请使用微信扫描上方二维码登录</p>
            </div>
        </div>
    );
};

export default WeChatLoginComponent;
