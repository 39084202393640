import { HandleApiError } from "./ErrorHandler"; // 引入错误处理工具

/**
 * 通用的 fetch 封装函数，自动处理错误
 * @param {string} url - 请求的 URL
 * @param {RequestInit} options - fetch 的配置选项
 * @returns {Promise<any>} 返回解析后的 JSON 数据
 */
export async function FetchWithErrorHandling(
    url: string,
    options: RequestInit
): Promise<any> {
    try {
        const response = await fetch(url, options);

        // 成功响应直接返回
        if (response.ok) {
            return await response.json();
        }

        throw response;

    } catch (error: any) {
        throw await HandleApiError(error);
    }
}