// src/containers/ChatContainer.tsx
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Layout, Menu, Modal, message } from "antd";
import { PieChartOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import ChatComponent from "../components/ChatComponent";
import { BASE_URL } from "../utils/BaseInfo";
import { useAuth } from "../contexts/AuthContext";
// import { handleFetchError } from "../utils/ErrorHandler";
import { ProChatInstance, ChatMessage } from "@ant-design/pro-chat";

const { Sider, Content } = Layout;

interface Session {
    id: string;
    title: string;
}

const ChatContainer: React.FC = () => {
    const navigate = useNavigate();
    const { sessionId } = useParams<{ sessionId: string }>();
    const { getToken } = useAuth();
    const token = getToken();
    const [collapsed, setCollapsed] = useState(false);

    const [sessions, setSessions] = useState<Session[]>([]);
    const [selectedSessionId, setSelectedSessionId] = useState<string>(sessionId || "new");

    // 从 ChatComponent 移动过来的状态和引用
    const [loading, setLoading] = useState(true);
    const [initialChats, setInitialChats] = useState<ChatMessage<Record<string, any>>[]>([]);
    const [error, setError] = useState<{ message: string; type: string } | null>(null);
    const proChatRef = useRef<ProChatInstance | undefined>(undefined);  // 设置为 ProChatInstance | undefined

    const apiUrl = BASE_URL;

    // 获取会话列表
    const fetchSessions = useCallback(async () => {
        try {
            const response = await fetch(`${apiUrl}/api/chat/session`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();

            const newSession = { id: "new", title: "新对话" };
            const sessionsWithNew = [newSession, ...data];

            setSessions(sessionsWithNew);

            if (data.length > 0 && !sessionId) {
                setSelectedSessionId(data[0].id);
                navigate(`/chat/${data[0].id}`);
            } else if (data.length === 0) {
                setSelectedSessionId("new");
                navigate(`/chat/new`);
            }
        } catch (error) {
            // handleFetchError(error, navigate);
        }
    }, [token, navigate, sessionId, apiUrl]);

    useEffect(() => {
        fetchSessions();
    }, [fetchSessions]);

    // 获取会话数据
    const fetchSessionData = useCallback(
        async (id: string) => {
            setLoading(true);
            try {
                const response = await fetch(`${apiUrl}/api/chat/session/${id}`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setInitialChats(data.initialChats);
                } else {
                    setError({ message: "获取会话数据失败", type: "error" });
                }
            } catch (error) {
                setError({ message: "获取会话数据失败", type: "error" });
            }
            setLoading(false);
        },
        [apiUrl, token]
    );

    useEffect(() => {
        if (selectedSessionId !== "new") {
            fetchSessionData(selectedSessionId);
        } else {
            setLoading(false);
            setInitialChats([]);
        }
    }, [selectedSessionId, fetchSessionData]);

    // 创建新会话
    const createNewSession = useCallback(async () => {
        try {
            const response = await fetch(`${apiUrl}/api/chat/session`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                setSelectedSessionId(data.sessionId); // 更新会话 ID
                navigate(`/chat/${data.sessionId}`);
                return data.sessionId;
            } else {
                setError({ message: "创建新会话失败", type: "error" });
                return null;
            }
        } catch (error) {
            setError({ message: "创建新会话失败", type: "error" });
            return null;
        }
    }, [apiUrl, token, navigate]);

    // 处理请求
    const handleRequest = async (messages: ChatMessage<Record<string, any>>[]) => {
        let activeSessionId = selectedSessionId;

        if (selectedSessionId === "new") {
            activeSessionId = await createNewSession();
            if (!activeSessionId) {
                throw new Error("创建新会话失败");
            }
        }

        try {
            const response = await fetch(`${apiUrl}/api/chat/stream-sse`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    userInput: messages[messages.length - 1].content,
                    sessionId: activeSessionId,
                    model: "GPT_3_5_Turbo",
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            if (!response.body) {
                throw new Error("Stream not supported");
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder("utf-8");

            const stream = new ReadableStream({
                async start(controller) {
                    function push() {
                        reader
                            .read()
                            .then(({ done, value }) => {
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                try {
                                    const chunk = decoder.decode(value, { stream: true });
                                    const lines = chunk.split("\n");

                                    for (let line of lines) {
                                        if (line.startsWith("data: ")) {
                                            const message = line.replace("data: ", "").trim();
                                            if (message) {
                                                const parsed = JSON.parse(message);
                                                if (parsed.Response) {
                                                    controller.enqueue(new TextEncoder().encode(parsed.Response));
                                                }
                                            }
                                        }
                                    }
                                    push();
                                } catch (error) {
                                    controller.error(error);
                                }
                            })
                            .catch((error) => {
                                controller.error(error);
                            });
                    }
                    push();
                },
            });

            return new Response(stream, {
                headers: { "Content-Type": "text/plain" },
            });
        } catch (error) {
            setError({
                message: error instanceof Error ? error.message : "未知错误",
                type: "error",
            });
            throw error;
        }
    };

    // 确认删除会话
    const confirmDeleteSession = (id: string) => {
        Modal.confirm({
            title: "确认删除对话",
            content: "您确定要删除这个对话吗？此操作不可撤销。",
            okText: "确认",
            cancelText: "取消",
            onOk: () => handleDeleteSession(id),
        });
    };

    // 处理删除会话
    const handleDeleteSession = async (id: string) => {
        try {
            const response = await fetch(`${apiUrl}/api/chat/session/${id}`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            message.success("对话已成功删除");
            fetchSessions();
            if (selectedSessionId === id) {
                setSelectedSessionId("new");
                navigate("/chat/new");
            }
        } catch (error) {
            // handleFetchError(error, navigate);
        }
    };

    // 菜单项
    const menuItems = sessions.map((session) => ({
        key: session.id,
        icon: <PieChartOutlined />,
        label: (
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span
                    onClick={() => {
                        setSelectedSessionId(session.id);
                        navigate(`/chat/${session.id}`);
                    }}
                >
                    {session.title}
                </span>
                {session.id !== "new" && (
                    <DeleteOutlined
                        onClick={(e) => {
                            e.stopPropagation();
                            confirmDeleteSession(session.id);
                        }}
                        style={{ marginLeft: 10, color: "red" }}
                    />
                )}
            </div>
        ),
    }));

    return (
        <Layout>
            <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
                <Menu theme="dark" selectedKeys={[selectedSessionId]} mode="inline" items={menuItems} />
            </Sider>
            <Content style={{ margin: "24px 16px", padding: 24, background: "#fff" }}>
                <ChatComponent
                    loading={loading}
                    error={error}
                    initialChats={initialChats}
                    handleRequest={handleRequest}
                    proChatRef={proChatRef}  // 传递 ref
                />
            </Content>
        </Layout>
    );
};

export default ChatContainer;
