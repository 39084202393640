import React, { useState } from "react";
import { Button, Input, Form, Typography, Alert } from "antd";

const { Text } = Typography;

const ForgetPasswordComponent: React.FC = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [verificationSent, setVerificationSent] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    // 发送验证码
    const handleSendCode = async (values: { email: string }) => {
        try {
            setLoading(true);
            setErrorMessage(null);
            setSuccessMessage(null);

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/account/sendverificationCode`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email: values.email }),
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.message || "发送验证码失败，请稍后再试！");
            }

            const data = await response.json();
            setSuccessMessage(data.Message);
            setVerificationSent(data.isExistingUser);
        } catch (err: any) {
            setErrorMessage(err.message || "发送验证码失败，请稍后再试！");
        } finally {
            setLoading(false);
        }
    };

    // 提交重置密码
    const handleResetPassword = async (values: { email: string; newPassword: string; confirmPassword: string; code: string }) => {
        try {
            setLoading(true);
            setErrorMessage(null);
            setSuccessMessage(null);

            if (values.newPassword !== values.confirmPassword) {
                setErrorMessage("两次输入的密码不一致！");
                setLoading(false);
                return;
            }

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/account/resetpassword`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email: values.email,
                    newPassword: values.newPassword,
                    confirmPassword: values.confirmPassword,
                    code: values.code,
                }),
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.message || "密码重置失败，请稍后再试！");
            }

            const data = await response.json();
            setSuccessMessage(data.message);
            form.resetFields();
            setVerificationSent(false);
        } catch (err: any) {
            setErrorMessage(err.message || "密码重置失败，请稍后再试！");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ padding: "20px 0" }}>
            <Form
                form={form}
                onFinish={verificationSent ? handleResetPassword : handleSendCode}
                layout="vertical"
                style={{
                    maxWidth: 400,
                    margin: "0 auto",
                    padding: 20,
                }}
            >
                {/* 邮箱输入框 */}
                <Form.Item
                    name="email"
                    label={<Text style={{ fontSize: 16, fontWeight: 500 }}>邮箱</Text>}
                    rules={[
                        { required: true, message: "请输入邮箱地址！" },
                        { type: "email", message: "请输入有效的邮箱地址！" },
                    ]}
                >
                    <Input
                        placeholder="example@domain.com"
                        size="large"
                        style={{
                            borderRadius: 8,
                            padding: "10px",
                            fontSize: 14,
                            border: "1px solid #d9d9d9",
                            boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                        }}
                        disabled={verificationSent} // 如果验证码已发送，禁用邮箱输入框
                    />
                </Form.Item>

                {/* 验证码输入框 */}
                {verificationSent && (
                    <Form.Item
                        name="code"
                        label={<Text style={{ fontSize: 16, fontWeight: 500 }}>验证码</Text>}
                        rules={[{ required: true, message: "请输入验证码！" }]}
                    >
                        <Input
                            placeholder="请输入验证码"
                            size="large"
                            style={{
                                borderRadius: 8,
                                padding: "10px",
                                fontSize: 14,
                                border: "1px solid #d9d9d9",
                                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                            }}
                        />
                    </Form.Item>
                )}

                {/* 新密码输入框 */}
                {verificationSent && (
                    <Form.Item
                        name="newPassword"
                        label={<Text style={{ fontSize: 16, fontWeight: 500 }}>新密码</Text>}
                        rules={[
                            { required: true, message: "请输入新密码！" },
                            { min: 6, message: "密码长度至少为6位！" },
                        ]}
                    >
                        <Input.Password
                            placeholder="请输入新密码"
                            size="large"
                            style={{
                                borderRadius: 8,
                                padding: "10px",
                                fontSize: 14,
                                border: "1px solid #d9d9d9",
                                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                            }}
                        />
                    </Form.Item>
                )}

                {/* 确认密码输入框 */}
                {verificationSent && (
                    <Form.Item
                        name="confirmPassword"
                        label={<Text style={{ fontSize: 16, fontWeight: 500 }}>确认密码</Text>}
                        rules={[{ required: true, message: "请再次输入新密码！" }]}
                    >
                        <Input.Password
                            placeholder="请再次输入新密码"
                            size="large"
                            style={{
                                borderRadius: 8,
                                padding: "10px",
                                fontSize: 14,
                                border: "1px solid #d9d9d9",
                                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                            }}
                        />
                    </Form.Item>
                )}

                {/* 错误提示 */}
                {errorMessage && (
                    <Alert
                        message={errorMessage}
                        type="error"
                        showIcon
                        style={{ marginBottom: 20 }}
                    />
                )}

                {/* 成功提示 */}
                {successMessage && (
                    <Alert
                        message={successMessage}
                        type="success"
                        showIcon
                        style={{ marginBottom: 20 }}
                    />
                )}

                {/* 提交按钮 */}
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        block
                        size="large"
                        loading={loading}
                        style={{
                            borderRadius: 8,
                            fontSize: 16,
                            fontWeight: 500,
                            padding: "10px 0",
                        }}
                    >
                        {verificationSent ? "重置密码" : "发送验证码"}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default ForgetPasswordComponent;
