// src/pages/ChatPage.tsx
import React from "react";
import { Layout } from "antd";
import ChatContainer from "../containers/ChatContainer";

const { Header, Footer } = Layout;

const ChatPage: React.FC = () => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header style={{ color: "#fff", textAlign: "center" }}>Header</Header>
      <ChatContainer />
      <Footer style={{ textAlign: "center" }}>dob!~</Footer>
    </Layout>
  );
};

export default ChatPage;
