// src/app/appInitializer.tsx

import { fetchAndCacheErrors } from "../api/ErrorService";
import { setLanguage } from "../utils/Languages";

// 初始化错误信息缓存
export async function initializeErrorCache() {
    // 自动检测浏览器语言
    const browserLanguage = navigator.language; // 获取标准的浏览器语言属性

    // 判断语言是否为中文
    if (browserLanguage.startsWith("zh")) {
        setLanguage("zh");
    } else {
        setLanguage("en");
    }

    console.log("当前语言设置为:", browserLanguage.startsWith("zh") ? "zh" : "en");

    // 初始化错误信息缓存
    const cachedData = localStorage.getItem("errorCache");
    if (!cachedData) {
        console.log("没有缓存，从后端获取错误信息...");
        await fetchAndCacheErrors();
    }
}