import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { isTokenExpired, validateToken } from "../services/AuthService";
import { Spin, message } from "antd";

//后面需要放在context文件夹下
interface AuthContextType {
  isLoggedIn: boolean;
  login: (token: string) => void;
  logout: () => void;
  getToken: () => string | null;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);


export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // 在开发环境中自动模拟登录
    // if (process.env.NODE_ENV === "development") {
    //   console.log("开发模式：跳过登录");
    //   setIsLoggedIn(true);
    //   setLoading(false);
    //   return;
    // }

    // 正常的身份验证逻辑
    const token = localStorage.getItem("token");
    const checkToken = async () => {
      if (token && !isTokenExpired(token)) {
        const isValid = await validateToken(token);
        if (isValid) {
          setIsLoggedIn(true);
        } else {
          localStorage.removeItem("token");
          message.error("Your session has expired. Please log in again.");
        }
      } else {
        localStorage.removeItem("token");
      }
      setLoading(false);
    };
    checkToken();
  }, []);

  const login = (token: string) => {
    localStorage.setItem("token", token);
    setIsLoggedIn(true);
  };

  const logout = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
  };

  const getToken = () => localStorage.getItem("token");

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "20%" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout, getToken }}>
      {children}
    </AuthContext.Provider>
  );
};


export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

interface ProtectedRouteProps {
  element: JSX.Element;
  redirectTo: string;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  element,
  redirectTo,
}) => {
  const { isLoggedIn } = useAuth();
  return isLoggedIn ? element : <Navigate to={redirectTo} />;
};
